#my_canvas {
  aspect-ratio: auto 100 / 100;
  margin-left: 6cm;
}

#onlyitems {
  list-style: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  display: inline-flex;
}

#links {
  border-radius: 7px;
  /* margin-top: 75%; */
  /* margin-left: 90%; */
  margin: 0 auto;
  margin-top: -790px;
  margin-left: 9%;
}

li {
  transition: transform 0.7s;
}
li:hover {
  transform: translate(0px, -10%);
}

@media only screen and (min-width: 1522px) and (max-width: 3456px) {
  #my_canvas {
    margin-left: 14cm;
  }
}
