.father {
  text-align: center;
}

.child {
  margin-top: 100%;
  height: 600px;
  background-color: aqua;
}

.child2 {
  height: 600px;
  background-color: beige;
  padding-top: 5px;
  /* margin-bottom: %; */
}
.link {
  text-decoration: none;
  display: inline;
  padding: 20px 20px;
  font-weight: 700;
  font-size: large;
}

/* * {
  box-sizing: border-box;
} */

/* body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
} */
#titleName {
  color: #333;
  font-family: tahoma;
  font-size: 3rem;
  font-weight: 100;
  line-height: 1.5;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  width: 550px;
}

h1 span {
  font-size: 40px;
  margin-left: 40px;
}

.message {
  background-color: yellow;
  color: #333;
  display: block;
  font-weight: 900;
  overflow: hidden;
  position: absolute;
  padding-left: 0.5rem;
  top: 0.2rem;
  left: 130px;
  animation: openclose 9s ease-in infinite;
}

.word1,
.word2,
.word3 {
  font-family: tahoma;
}

@keyframes openclose {
  0% {
    top: 0.2rem;
    width: 10;
  }
  5% {
    width: 0;
  }
  20% {
    width: 250px;
  }
  30% {
    top: 0.2rem;
    width: 364px;
  }
  33% {
    top: 0.2rem;
    width: 0;
  }
  35% {
    top: 0.2rem;
    width: 0;
  }
  38% {
    top: -4.5rem;
  }
  48% {
    top: -4.5rem;
    width: 190px;
  }
  62% {
    top: -4.5rem;
    width: 350px;
  }
  66% {
    top: -4.5rem;
    width: 0;
    text-indent: 0;
  }
  71% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  86% {
    top: -9rem;
    width: 285px;
  }
  95% {
    top: -9rem;
    width: 285px;
  }
  98% {
    top: -9rem;
    width: 0;
    text-indent: 5px;
  }
  100% {
    top: 0;
    width: 0;
    text-indent: 0;
  }
}

.up {
  margin-left: -35%;
  padding-top: 45px;
}
