.cardinfo {
  max-width: 350px;
  box-shadow: 0 0px 0px 0 gray;
  margin: auto;
  /* text-align: center; */
  border-radius: 7px;
  position: static;
  margin-top: 30px;
  margin-right: 50%;
}

.name {
  font-family: monospace;
  text-align: center;
  margin-top: 10px;
}

.titlename1 {
  color: black;
  font-size: 15px;
  text-align: center;
}

#contact {
  border: none;
  color: white;
  background-color: black;
  text-align: center;
  width: 103.1%;
  font-size: 15px;
  text-decoration: underline;

  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

#contact:hover {
  opacity: 0.8;
}

#closecard {
  position: relative;
  border: none;
  background-color: aliceblue;
}

#myinfo {
  /* box-shadow: 0 5px 9px 0 gray; */
  max-width: 350px;
  height: 365px;
  border-radius: 7px;
  background-color: aliceblue;
  margin: 0 auto;
  margin-top: -28%;
  margin-right: 15%;
  transition: 0.25s ease;
}

#myinfo:hover {
  transform: translate(10);
}

#phone {
  /* margin-left: 30%; */
  font-family: "Courier New", Courier, monospace;
}

#address {
  margin-left: 30%;
  font-family: "Courier New", Courier, monospace;
}

#insideinfo {
  text-align: left;
  /* margin-top: 74px; */
  /* margin-top: 50 auto; */
}

.sent {
  margin-top: 3%;
  margin-left: 20%;
  font-size: x-small;
  font-weight: bold;
}

#photo {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  aspect-ratio: auto 1 / 1;
}

.details {
  margin-top: -10%;
  margin-left: 90px;
}

#name {
  margin-bottom: 3%;
}

#email {
  margin-bottom: 3%;
}

#company {
  margin-bottom: 3%;
}

#h {
  margin-left: 25%;
  margin-top: 17%;
  margin-bottom: auto;
}

#btnn {
  margin-left: 55px;
  margin-top: 3%;
}

.material-icons {
  margin-left: 100%;
}

#titlename {
  display: table-header-group;
  font-size: small;
}

#titlemail {
  display: table-header-group;
  font-size: small;
}

#titlecomp {
  display: table-header-group;
  font-size: small;
}

#titledis {
  display: table-header-group;
  font-size: small;
}

#t {
  border-radius: 7px;
  border-style: solid;
}

#p {
  border-radius: 7px;
  border-style: solid;
}

#n {
  border-radius: 7px;
  border-style: double;
}

#j {
  border-radius: 7px;
  border-style: solid;
}

@media only screen and (min-width: 1522px) and (max-width: 3456px) {
  #myinfo {
    text-align: left;
    margin-top: -34%;
    margin-right: 25%;
  }

  .cardinfo {
    margin-right: 55%;
    margin-bottom: 12%;
    margin-top: -10%;
  }
}
