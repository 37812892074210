.header {
  color: white;
  position: relative;
  width: 100%;
  height: 100%;
  /* //#00ABCA */
}

.title {
  font-size: 2rem;
  margin-left: 41%;
  margin-top: 5px;

  background-image: linear-gradient(
    -225deg,
    #bebace 0%,
    #a49bac 29%,
    #d6a7b6 67%,
    #c5c49e 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 8s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.container {
  width: 100%;
  height: 100%;
  /* overflow-y: scroll; */
  /* scroll-behavior: smooth; */
}

.nav {
  position: fixed;
  top: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 40px;
  margin-left: -38%;
  /* background-color: #bff6f7; */
}

.nav ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  text-decoration-line: none;
}

.word {
  margin: 0 2rem;
  margin-left: 35%;
  margin-top: -0.5%;
  font-size: 21px;
  position: relative;
  color: #30241b;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.link {
  color: #30241b;
  text-decoration-line: none;
}

/* 
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 10px;
gap: 10px;
position: relative;
width: 70px;
height: 20px;
background-color: #3a58c2;
color: rgba(221, 151, 151, 1);
border-radius: 16px; */

.father {
  text-align: center;
}

.section {
  height: 650px;
  background-color: white;
  padding-top: 70px;
}

.section2 {
  height: 1095px;
  background-color: white;
}

.section3 {
  height: 547px;
  background-color: white;
  padding-top: 40px;
}

.btn {
  padding: 10px 30px;
  background-color: rgb(229, 235, 239);
  outline: none;
  border: 4px solid;
  color: rgb(107, 117, 124);
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
}

#btn1 {
  border-image: -webkit-linear-gradient(left, #009efa, #4ffbdf) 1;
}

@media only screen and (min-width: 1522px) and (max-width: 3456px) {
  .section {
    /* height: 1000px; */
    height: 905px;
    background-color: white;
    padding-top: 178px;
  }

  .section2 {
    height: 1400px;
  }

  .section3 {
    padding-top: 300px;
  }
}
