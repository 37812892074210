@media only screen and (min-width: 375px) and (max-width: 2000px) {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #e0fbfc; */
    width: 100%;
    height: 100%;
  }
}

code {
  font-family: Arial, Helvetica, sans-serif;
  size: 50%;
}

.dropdown {
  position: relative;
  margin-top: -950px;
  right: 83%;
}

.label {
  cursor: pointer;
  background: #3d5a80;
  padding: 0.1rem 1.3rem;
  width: 15%;
  display: block;
  box-sizing: border-box;
  transition: all 0.3s;
  border-radius: 7px;

  /* top: 100%; */
}

.items a {
  color: white;
  display: list-item;
  align-items: center;
  padding: 0.2rem 0.2rem;
  text-decoration: none;
  transition: all 0.2s;
  /* border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px; */
}

.items a:hover {
  border-left: 10px solid;
  background: rgb(37, 37, 37);
}

.fa-brands,
.fa-solid {
  margin-left: 10px;
  padding: 5px;
}

.items {
  background: rgb(52, 52, 52);
  opacity: 0;
  visibility: hidden;
  min-width: 10%;
  height: 0;
  position: absolute;
  top: 48px;
  transform-origin: top;
  transform: scaleY(0);
  transition: transform 0.3s;
  border-radius: 7px;
}

.items::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: rgb(52, 52, 52);
  transform: rotate(45deg);
  top: -7px;
  left: 20px;
  z-index: -1;
}

.dropdown:hover > .items {
  opacity: 1;
  visibility: visible;
  height: unset;
  transform: scaleY(1);
}

.dropdown:hover > .label {
  background: #3d5a80;
}
