.cssf p {
  border-right: 0.15em solid orange;
  font-family: "Courier";
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
}

.cssf p:nth-child(1) {
  /* visibility: hidden; */
  /* width: 29.3em; */
  opacity: 1;
  -webkit-animation: type 5s steps(40), blink 0.5s step-end infinite alternate;
  animation: type 5s steps(40), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.cssf p:nth-child(2) {
  /* width: 70.3em; */
  opacity: 1;
  -webkit-animation: type2 5s steps(27), blink 0.5s step-end infinite alternate;
  animation: type2 5s steps(27), blink 0.5s step-end infinite alternate;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-left: 10px;
}

@keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid orange;
  }
  100% {
    border: none;
  }
}

#labeling {
  display: block;
  /* padding-left: 544px; */
  font-weight: bold;
  text-align: center;
}

.cssf input {
  margin-left: 570px;
  border-style: double;
  width: 10%;
}

.cssf button {
  padding: 5px 42px;
  margin-left: 550px;
  margin-top: 10px;
  background-color: rgb(229, 235, 239);
  outline: none;
  border: 4px solid;
  color: rgb(107, 117, 124);
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
  border-image: -webkit-linear-gradient(left, #009efa, #4ffbdf) 1;
}

.cssf1,
.text {
  box-shadow: 0 7px 40px #009efa;
  width: 53%;
  margin-left: 301px;
  padding: 3px;
  margin-top: 3%;
  display: block;
}

.cssf1 ul {
  list-style: none;
  font-size: large;
  text-align: center;
  margin-left: -2%;
}

.cssf1 ul li {
  font-size: small;
  margin-left: 0%;
}

.qfe {
  display: none;
  text-align: center;
}

.hoveron {
  text-align: center;
  /* margin-left: 5%; */
}

.hoveron:hover + .qfe {
  display: block;
}

@media only screen and (min-width: 1522px) and (max-width: 3456px) {
  .cssf input {
    border-style: double;
    margin-left: 850px;
    width: 10%;
  }
  .cssf2 {
    margin-left: 16.5%;
  }

  .cssf1 {
    margin-left: 460px;
  }

  .text {
    margin-left: 460px;
  }

  #first {
    margin-left: -19%;
  }
}
