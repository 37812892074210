.dropdown {
  position: relative;
  cursor: pointer;
  bottom: 100px;
}

.dropdown > a {
  display: flex;
  align-items: center;
  gap: 2px;
  height: 72px;
  padding-left: 900%;
  margin-top: 95%;
}

.dropdown:hover > .menu {
  opacity: 1;
  visibility: visible;
}

.menu > a {
  font-size: 14px;
  padding: 12px 20px;
}

.menu > a:hover {
  background: #c0d0da;
}

.menu {
  position: absolute;
  top: 165px;
  left: 845%;
  display: grid;
  width: 180px;
  padding: 8px 0;
  background: #ffff;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  box-shadow: 0 400px 40px rgb(0 0 0 / 6%);
  opacity: 0;
  visibility: hidden;
  transition: 4s;
  color: #009efa;
}

.menu::before {
  content: "";
  background: inherit;
  border-top: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
  position: absolute;
  top: -7px;
  right: 22px;
  width: 12px;
  height: 12px;
  rotate: -45deg;
}

.titleof {
  font-size: 150%;
  transition: transform 0.9s;
  color: #30241b;
}

.titleof:hover {
  transform: translate(0px, -4%);
}

.btn {
  padding: 10px 30px;
  background-color: black;
  outline: none;
  border: 4px solid;
  color: white;
  text-decoration: none;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
}

#btn1 {
  border-image: -webkit-linear-gradient(left, #009efa, #4ffbdf) 1;
}

a {
  font-weight: bold;
}

@media only screen and (min-width: 1522px) and (max-width: 3456px) {
  .dropdown > a {
    display: flex;
    align-items: center;
    gap: 2px;
    height: 72px;
    padding-left: 1405%;
    margin-top: 90%;
  }

  .menu {
    position: absolute;
    top: 165px;
    left: 1330%;
    display: grid;
    width: 180px;
    padding: 8px 0;
    background: #ffff;
    border-radius: 10px;
    border: 1px solid #e1e1e1;
    box-shadow: 0 400px 40px rgb(0 0 0 / 6%);
    opacity: 0;
    visibility: hidden;
    transition: 4s;
    color: #009efa;
  }
}
